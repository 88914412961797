import {
  // PAGE_NAME_CINEMA,
  PAGE_NAME_X_CROSS_8,
  PAGE_NAME_X_CROSS_7,
} from "@/data/constants";

export const PANORAMA_LIST = [
  {
    src: require("@/assets/img/hall/light-q85.webp"),
    srcMobile: require("@/assets/img/hall/mobile/light-q25.webp"),
    startLookAt: 245,
  },
];

export const INFOSPOT_LIST = [
  // Закомменчено до добавления в шоурум нового авто

  {
    // x-cross-8
    position: {
      x: 2250,
      y: -900,
      z: 3000,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_left",
    nextPage: PAGE_NAME_X_CROSS_8,
    areaHover: {
      position: {
        x: 3300,
        y: -300,
        z: 3100,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 140,
      },
    },
  },
  // {
  //   // кинотеатр
  //   position: {
  //     x: 5000,
  //     y: -100,
  //     z: 0,
  //   },
  //   size: 360,
  //   sizeMobile: 450,
  //   className: "popover_id_main_center",
  //   nextPage: PAGE_NAME_CINEMA,
  // },
  {
    // x-cross-7
    position: {
      x: 3600,
      y: -900,
      z: -600,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_right",
    nextPage: PAGE_NAME_X_CROSS_7,
    areaHover: {
      position: {
        x: -3300,
        y: -300,
        z: -3100,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 50,
      },
    },
  },
];
