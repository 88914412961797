<template>
  <nav class="nav">
    <ul>
      <li v-for="(item, index) in links" :key="index">
        <router-link class="button-car" :to="item.to" @click="onClick(item.to)">
          <img :src="item.src" alt="" />
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { PAGE_NAME_X_CROSS_8, PAGE_NAME_X_CROSS_7 } from "@/data/constants";
import { gtmXCross7Cross, gtmVestaSedan } from "@/utils/gtm";

export default {
  data() {
    return {
      links: [
        // {
        //   name: "VESTA SE",
        //   to: { name: PAGE_NAME_X_CROSS_8 },
        //   src: require("@/assets/img/cars/vesta-se/preview/mobile/preview-se.webp"),
        // },
        {
          name: "X-Cross 8",
          to: { name: PAGE_NAME_X_CROSS_8 },
          src: require("@/assets/img/cars/x-cross-8/preview/mobile/preview-sw.webp"),
        },
        {
          name: "X-Cross 7",
          to: { name: PAGE_NAME_X_CROSS_7 },
          src: require("@/assets/img/cars/x-cross-7/preview/mobile/preview-sw.webp"),
        },
      ],
    };
  },
  methods: {
    onClick({ name }) {
      if (name === PAGE_NAME_X_CROSS_8) {
        gtmVestaSedan();
      } else if (name === PAGE_NAME_X_CROSS_7) {
        gtmXCross7Cross();
      }
    },
  },
};
</script>

<style lang="scss">
.nav {
  ul {
    @include reset-list;
    margin-top: uniWM(16);
    display: flex;
    // grid-template-columns: 1fr;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: uniWM(12);
    text-align: center;
    font-weight: 700;
    font-size: uniWM(16);
    text-transform: uppercase;
    color: var(--colorWhite);
    li {
      position: relative;
      width: 50%;
      height: 50%;

      &::before {
        content: "";
        position: absolute;
        left: -10%;
        top: 20px;
        z-index: -1;
        width: 232px;
        height: 83px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(154, 154, 154, 0.8) 0%,
          rgba(98, 98, 98, 0) 100%
        );
      }
      a {
        display: block;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>
