<template>
  <div
    ref="panoramaMain"
    class="panorama-container panorama-main"
    :class="{ show: $route.name === 'home' }"
  ></div>
  <div
    class="popover-backdrop popover-backdrop_type_inactive popover-backdrop_type_light"
    :class="{ popover_state_show: pinActive }"
  ></div>
  <div style="display: none">
    <div class="popover-core popover-core_type_inactive popover_id_main_left">
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="XCITE<br/>X-Cross 8" />
    </div>
    <div
      class="popover-core popover-core_type_inactive popover_id_main_center button-cinema"
    >
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="КИНОТЕАТР" />
    </div>
    <div
      class="popover-core popover-core_type_inactive popover_id_main_right button-x-cross-7"
    >
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="XCITE<br/>X-Cross 7" />
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import * as PANOLENS from "@/libs/panolens/panolens.js";
import { PANORAMA_LIST, INFOSPOT_LIST } from "@/data/hall";
import { mapState } from "vuex";
import {
  PAGE_NAME_CINEMA,
  PAGE_NAME_X_CROSS_8,
  PAGE_NAME_X_CROSS_7,
} from "@/data/constants";
import { degToRad } from "@/utils/helpers";
import Popover from "@/components/Popover.vue";
import { gtmCinema, gtmXCross7Cross, gtmVestaSedan } from "@/utils/gtm";

const markerImage = require("@/assets/img/icons/marker-eye.svg");
const markerImageDark = require("@/assets/img/icons/marker-eye-dark.svg");

export default {
  name: "PanoramaMain",
  components: {
    Popover,
  },
  data() {
    return {
      panorama: [],
      viewer: null,
      cameraStartPosition: {},
      pinActive: false,
    };
  },
  computed: {
    ...mapState(["theme", "loading", "isMobile", "feedbackModalShow"]),
  },
  watch: {
    loading() {
      this.init();
    },
    feedbackModalShow(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
    pinActive(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
  },
  methods: {
    init() {
      if (this.viewer) return false;
      this.setupViewer();

      PANORAMA_LIST.forEach((options) => {
        const source = this.isMobile ? options.srcMobile : options.src;
        const panorama = this.addPanorama(source);
        this.startLookAt(panorama, options.startLookAt);
      });

      this.addMarkers();
    },
    disableKeys() {
      this.viewer.disableKeys();
    },
    enableKeys() {
      this.viewer.enableKeys();
    },
    startLookAt(panorama, deg, axios = "y") {
      const angle = degToRad(deg);

      panorama.rotation[axios] = angle;
    },
    addPanorama(url) {
      const panorama = new PANOLENS.ImagePanorama(url);
      this.viewer.add(panorama);
      this.panorama.push(panorama);

      return panorama;
    },
    setPanorama(panorama) {
      this.viewer.setPanorama(panorama);
    },
    setCameraPosition(x, y, z) {
      this.viewer.camera.position.x = x ? x : this.viewer.camera.position.x;
      this.viewer.camera.position.y = y ? y : this.viewer.camera.position.y;
      this.viewer.camera.position.z = z ? z : this.viewer.camera.position.z;
    },
    getParams() {
      const urlParams = new URLSearchParams(location.search);
      const fov = urlParams.get("fov");
      const posZ = urlParams.get("posZ");
      return { fov, posZ };
    },
    setupViewer() {
      const defaultFov = this.isMobile ? 80 : 46;
      const defaultCameraRotationX = this.isMobile ? 0 : -30;
      const defaultCameraPositionZ = this.isMobile ? 500 : 1500;

      const container = this.$refs.panoramaMain;
      const { fov, posZ } = this.getParams();
      const cameraFov = fov || defaultFov;
      const cameraPositionZ = posZ || defaultCameraPositionZ;
      const coofAxisX = this.isMobile ? 3 : 5;
      const coofAxisYTop = 2.5;
      const coofAxisYBottom = 3;

      this.viewer = new PANOLENS.Viewer({
        container: container,
        cameraFov: cameraFov,
        controlBar: false,
        output: "console",
        autoHideInfospot: false,
        name: "main",
      });

      if (cameraPositionZ > 0) {
        this.viewer.camera.position.z = cameraPositionZ;
      }

      // horizontal
      this.viewer.OrbitControls.minAzimuthAngle = -Math.PI / coofAxisX;
      this.viewer.OrbitControls.maxAzimuthAngle = Math.PI / coofAxisX;

      // vertical
      this.viewer.OrbitControls.minPolarAngle = Math.PI / coofAxisYTop;
      this.viewer.OrbitControls.maxPolarAngle = (Math.PI * 2) / coofAxisYBottom;

      this.viewer.OrbitControls.noZoom = true;

      this.setCameraPosition(defaultCameraRotationX, 0, 0);
    },
    setCameraStartPosition(vector3) {
      this.cameraStartPosition = Object.assign({}, vector3);
    },
    addMarkers() {
      this.panorama.forEach((panorama, index) => {
        INFOSPOT_LIST.forEach((options) => {
          this.addMarker(panorama, options, index);
        });
      });
    },
    addMarker(panorama, options, panoramaIndex) {
      const { position, size, sizeMobile, className, nextPage, areaHover } =
        options;
      const scale = this.isMobile ? sizeMobile : size;
      const elHover = document.getElementsByClassName(className)[0];
      const marker = panoramaIndex === 1 ? markerImageDark : markerImage;
      const infospot = new PANOLENS.Infospot(scale, marker, false);

      infospot.position.set(position.x, position.y, position.z);
      infospot.addHoverElement(elHover, 0);
      panorama.add(infospot);

      infospot.addEventListener("click", this.onClick(nextPage));
      infospot.addEventListener("hoverenter", this.onHoverEnter());
      infospot.addEventListener("hoverleave", this.onHoverLeave());

      if (areaHover) {
        this.addArea(panorama, infospot, options);
      }
    },
    addArea(panorama, infospot, options) {
      const {
        areaHover: { position, size, rotate },
        nextPage,
      } = options;
      const geometry = new THREE.PlaneGeometry(size.width, size.height);
      const material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        opacity: 0,
        transparent: true,
      });
      const plane = new THREE.Mesh(geometry, material);

      plane.position.set(position.x, position.y, position.z);
      plane.rotation.y = THREE.Math.degToRad(rotate.y);

      plane.addEventListener("click", this.onClick(nextPage, infospot));
      plane.addEventListener("hoverenter", this.onHoverEnter(infospot));
      plane.addEventListener("hoverleave", this.onHoverLeave(infospot));

      panorama.add(plane);
    },
    onClick(nextPage, infospot) {
      return (e) => {
        if (infospot) {
          infospot.onHoverEnd(e);
          this.onHoverLeave(infospot)(e);
        } else {
          this.onHoverLeave()(e);
        }
        this.pinActive = false;
        this.toNext(nextPage);
      };
    },
    onHoverEnter(infospot) {
      return (e) => {
        if (this.isMobile) return true;
        if (infospot) {
          infospot.onHoverStart(e);
          infospot.element.classList.add("popover_state_show");
        } else {
          e.target.element.classList.add("popover_state_show");
        }
        this.pinActive = true;
      };
    },
    onHoverLeave(infospot) {
      return (e) => {
        if (this.isMobile) return true;
        if (infospot) {
          infospot.onHoverEnd(e);
          infospot.element.classList.remove("popover_state_show");
        } else {
          e.target.element.classList.remove("popover_state_show");
        }
        this.pinActive = false;
      };
    },
    toNext(pageName) {
      this.$router.push({ name: pageName });
      if (pageName === PAGE_NAME_CINEMA) {
        gtmCinema();
      } else if (pageName === PAGE_NAME_X_CROSS_8) {
        gtmVestaSedan();
      } else if (pageName === PAGE_NAME_X_CROSS_7) {
        gtmXCross7Cross();
      }
    },
  },
};
</script>
