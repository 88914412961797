<template>
  <transition name="fade-modal">
    <div v-if="feedbackModalShow" class="modal">
      <div class="modal-bg" @click="hideFeedbackModal"></div>
      <div class="modal-content modal-content_color_feedback">
        <div v-if="status === 'success'" class="form-message">
          <h3>СПАСИБО!</h3>
          <p>
            Ваша заявка принята. В ближайшее время наш специалист свяжется с
            вами.
          </p>
          <button class="btn-primary" @click="hideFeedbackModal">
            Закрыть
          </button>
        </div>
        <Form v-else ref="form" class="form" @submit="onSubmit">
          <div class="form-title">ОСТАВИТЬ ЗАЯВКУ</div>
          <div
            class="modal-close modal-close_type_inset"
            @click="hideFeedbackModal"
          >
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
              <path
                d="m17 16.9997-5.6569-5.6568m0 11.3137L17 16.9997l-5.6569 5.6569ZM17 16.9997l5.6569-5.6568L17 16.9997Zm0 0 5.6569 5.6569L17 16.9997Z"
                stroke="rgba(246, 167, 35, 1)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <FormItem
            v-slot="{ field }"
            name="model"
            class="form-model"
            :rules="isRequired"
            @click="onModelClick('model')"
          >
            <div class="form-select">
              <select v-bind="field" :class="{ selected: field.value }">
                <option value="" disabled selected>Выберите модель</option>
                <option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            v-if="!paramsDealer"
            v-slot="{ field }"
            name="city"
            class="form-city"
            :rules="isRequired"
            @change="onChangeCity"
            @click="onFieldClick('city')"
          >
            <div class="form-select">
              <select v-bind="field" :class="{ selected: field.value }">
                <option value="" disabled selected>Ваш город</option>
                <option
                  v-for="(item, index) in cities"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            v-if="!paramsDealer"
            v-slot="{ field }"
            name="dealer"
            class="form-dealer"
            :rules="isRequired"
            @click="onFieldClick('dealer')"
          >
            <div class="form-select">
              <select
                v-bind="field"
                :class="{ selected: field.value }"
                :disabled="!dealers.length"
              >
                <option value="" disabled selected>Выберите дилера</option>
                <option
                  v-for="(item, index) in dealers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            name="firstname"
            type="text"
            placeholder="Имя"
            class="form-firstname"
            :rules="isRequired"
            @click="onFieldClick('firstname')"
          />
          <FormItem
            name="lastname"
            type="text"
            placeholder="Фамилия"
            class="form-lastname"
            :rules="isRequired"
            @click="onFieldClick('lastname')"
          />
          <FormItem
            v-slot="{ field }"
            name="phone"
            type="tel"
            class="form-phonenumber"
            :rules="isRequiredPhone"
            @click="onFieldClick('phone')"
          >
            <input
              placeholder="Тел. номер"
              autocomplete="off"
              class="form-control"
              v-bind="field"
              type="tel"
              v-maska="maskPhone"
            />
          </FormItem>
          <FormItem
            name="email"
            type="email"
            placeholder="E-mail"
            class="form-email"
            :rules="validateEmail"
            @click="onFieldClick('email')"
          />
          <FormItem
            name="terms"
            type="checkbox"
            class="form-checkbox"
            :rules="isRequired"
            @change="onCheckboxClick()"
          >
            <span>
              Отправляя сообщение, я соглашаюсь с
              <a
                href="/static/processing_policy_06.06.2024.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >политикой обработки персональных данных</a
              >
              , выражаю свое согласие и разрешаю ООО «АВТОЗАВОД САНКТ-ПЕТЕРБУРГ»
              осуществлять обработку моих персональных данных (фамилия, имя,
              отчество, номер стационарного/мобильного телефона, адрес
              электронной почты), в том числе сбор, систематизацию, накопление,
              хранение, уточнение, использование, уничтожение персональных
              данных, передачу третьим лицам, перечень которых размещен на сайте
              по адресу: https://xcite-avto.ru/ в целях, связанных с
              продвижением товаров и услуг, включая: направление материалов
              рекламного характера, связанных с деятельностью ООО «АВТОЗАВОД
              САНКТ-ПЕТЕРБУРГ», проведение статистических исследований и
              маркетинговых программ (акций) и опросов (аудиты, анкетирования,
              исследования), в том числе с целью выяснения мнения пользователя
              относительно продукции ООО «АВТОЗАВОД САНКТ-ПЕТЕРБУРГ». Согласие
              на обработку персональных данных в соответствии с указанными выше
              условиями я предоставляю на 10 (десять) лет. Я уведомлен и
              согласен с тем, что указанное согласие может быть мной отозвано
              посредством направления письменного заявления заказным почтовым
              отправлением с описью вложения, либо вручено лично под подпись.
            </span>
            <span style="margin-top: 14px">
              Если вы не согласны предоставить согласие на обработку
              персональных данных, то для оказания данной услуги можете
              обратиться к ближайшему дилеру XCITE.
            </span>
            <span style="margin-top: 14px">
              Регистрационные формы на сайте используют сервис Yandex
              SmartCaptcha, который обрабатывает Ваши (пользователя) данные для
              верификации запросов и блокировки роботов.
            </span>
          </FormItem>
          <div class="form-foot">
            <p v-if="status === 'error'" class="form-error-message">
              {{ errorMessage }}
            </p>
            <button
              :disabled="status === 'loading'"
              class="btn-primary form-send-button"
            >
              ОТПРАВИТЬ ЗАПРОС
            </button>
          </div>
        </Form>
      </div>
    </div>
  </transition>
</template>

<script>
import FormItem from "@/components/FormItem.vue";
import { BASE_API, FEEDBACK_ID, FEEDBACK_MODEL_LIST } from "@/data/constants";
import {
  gtmApplicationCheckbox,
  gtmApplicationFields,
  gtmApplicationModel,
  gtmSubmitApplicationPush,
  gtmSubmitApplicationSend,
} from "@/utils/gtm";
import { subscribeCaptcha } from "@/utils/smartCaptchaInvisible";
import { isEmail, isRequired, validateStatus } from "@/utils/validate";
import { Form } from "vee-validate";
import { mapMutations, mapState } from "vuex";

export default {
  name: "FeedbackModal",
  components: {
    Form,
    FormItem,
  },
  computed: {
    ...mapState(["feedbackModalShow", "paramsCity", "paramsDealer"]),
  },
  data() {
    return {
      status: "pending", // pending, loading, success, error
      cities: [],
      dealers: [],
      modelList: FEEDBACK_MODEL_LIST,
      maskPhone: `+7 (###) ###-##-##`,
      errorMessage: "Ошибка",
    };
  },
  created() {
    document.addEventListener("keyup", this.keyPressEscape);
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  watch: {
    feedbackModalShow(val) {
      if (val) {
        this.status = "pending";
        this.$nextTick(this.update);
      }
    },
  },
  methods: {
    ...mapMutations(["hideFeedbackModal"]),
    update() {
      if (!this.paramsDealer && !this.cities.length) {
        this.fetchCities();
      }
      if (this.paramsCity) {
        this.$refs.form.setFieldValue("city", this.paramsCity);
        this.fetchDealers(this.paramsCity);
      }
      // this.$refs.form.setFieldValue("model", this.modelList[0].name); // устанавливаем по умолчанию первую модель
    },
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.hideFeedbackModal();
      }
    },
    isRequiredPhone(value) {
      return isRequired(value, 18);
    },
    isRequired(value) {
      return isRequired(value);
    },
    validateEmail(value) {
      return isEmail(value);
    },
    async fetchCities() {
      try {
        const response = await fetch(`${BASE_API}/api/cms/public/cities`, {
          method: "GET",
        });
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        this.cities = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDealers(cityId) {
      try {
        const response = await fetch(
          `${BASE_API}/api/cms/public/dealers/?city_id=${cityId}`,
          {
            method: "GET",
          }
        );
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        this.$refs.form.setFieldValue("dealer", "");
        this.dealers = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFeedback(data) {
      try {
        const response = await fetch(
          `${BASE_API}/api/cms/public/feedback_appeals`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
          }
        );
        const { status } = response;

        if (!validateStatus(status)) {
          const error = await response.json();

          this.errorMessage = error.message ? error.message : error;
          throw new Error(`server response with status ${status}`);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    onChangeCity(e) {
      this.dealers = [];
      this.fetchDealers(e.target.value);
    },
    onModelClick(name) {
      gtmApplicationModel();
      this.onFieldClick(name);
    },
    onFieldClick(name) {
      gtmApplicationFields(name);
    },
    onCheckboxClick() {
      gtmApplicationCheckbox();
    },
    async onSubmit(values) {
      this.status = "loading";
      gtmSubmitApplicationSend();

      const token = await subscribeCaptcha();
      const { lastname, firstname, phone, email, model } = values;
      const form = {
        "smart-token": token,
        feedback_id: FEEDBACK_ID,
        dealer_id: values.dealer,
        properties: {
          lastname,
          firstname,
          email,
          model_name: model,
          phone: `+${phone.replace(/\D/g, "")}`,
          url: location.href,
        },
      };

      if (this.paramsDealer) {
        form.dealer_id = +this.paramsDealer;
      }

      try {
        await this.fetchFeedback(form);
        this.status = "success";
        gtmSubmitApplicationPush(
          this.paramsDealer ? "ds_virtual_showroom_YA" : "virtual_showroom"
        );
      } catch (error) {
        this.status = "error";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-model {
  ::v-deep input {
    color: var(--colorGreyLight) !important;
  }
}
</style>
