<template>
  <Loading />
  <PanoramaMain />
  <TutorialModal />
  <FeedbackModal />
  <PopoverModal />
  <VideoModal />
  <EnjoyModal />
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" :key="route.name" />
    </keep-alive>
  </router-view>
</template>

<script>
import {
  PAGE_NAME_X_CROSS_8,
  PAGE_NAME_X_CROSS_7,
  THEMES,
} from "@/data/constants";
import { mapMutations, mapState } from "vuex";

import EnjoyModal from "@/components/EnjoyModal.vue";
import FeedbackModal from "@/components/FeedbackModal.vue";
import Loading from "@/components/Loading.vue";
import PanoramaMain from "@/components/PanoramaMain.vue";
import PopoverModal from "@/components/PopoverModal.vue";
import TutorialModal from "@/components/TutorialModal.vue";
import VideoModal from "@/components/VideoModal.vue";
import {
  gtmVestaSedanMultimediaHere,
  gtmXCross7MultimediaHere,
} from "@/utils/gtm";
import { renderCaptcha } from "@/utils/smartCaptchaInvisible";

export default {
  name: "HomeView",
  components: {
    Loading,
    PanoramaMain,
    TutorialModal,
    FeedbackModal,
    PopoverModal,
    VideoModal,
    EnjoyModal,
  },
  created() {
    document.addEventListener("showVideo", this.onClickVideo);
    document.addEventListener("showEnjoy", this.onClickEnjoy);
    renderCaptcha("smart-captcha-invisible");
  },
  computed: {
    ...mapState(["theme"]),
  },
  watch: {
    // Сбрасывает тему на светлую, когда пользователь находится не в просмотре интерьера
    $route: {
      handler() {
        const { mode } = this.$route.query;

        if (mode === "interior") return;
        if (this.theme === THEMES[0]) return;

        this.setTheme(THEMES[0]);

        setTimeout(() => {
          this.setTheme(THEMES[0]);
        }, 250);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations([
      "setLoading",
      "setVideo",
      "showVideoModal",
      "setEnjoyModel",
      "showEnjoyModal",
      "setTheme",
    ]),
    onClickVideo(e) {
      const media = e?.detail?.media;

      if (media !== undefined) {
        this.setVideo(media);
        this.showVideoModal();
      }
    },
    onClickEnjoy(e) {
      const model = e?.detail?.model;

      if (model !== undefined) {
        this.setEnjoyModel(model);
        this.showEnjoyModal();

        if (model === PAGE_NAME_X_CROSS_8) {
          gtmVestaSedanMultimediaHere();
        } else if (model === PAGE_NAME_X_CROSS_7) {
          gtmXCross7MultimediaHere();
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/app.scss";
@import "@/styles/modal.scss";
@import "@/styles/popover.scss";
@import "@/styles/swiper.scss";
@import "@/styles/form.scss";
@import "@/styles/panorama.scss";
@import "@/styles/button.scss";
</style>
